// src/PlayerSymbol.jsx
import React from "react";
import { Box } from "@mui/material";

const PlayerSymbol = ({ color, size = 24 }) => (
  <Box
    sx={{
      width: size,
      height: size,
      borderRadius: "50%",
      backgroundColor: color === "white" ? "#ffffff" : "#000000",
      border: "1px solid #000",
      display: "inline-block",
      marginRight: 1,
    }}
    aria-label={`${color.charAt(0).toUpperCase() + color.slice(1)} Player`}
  />
);

export default PlayerSymbol;
