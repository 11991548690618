// File: chessUtils.js

export const initializeStockfish = (
  stockfish,
  setEvaluation,
  setPvMoves,
  game
) => {
  if (stockfish.current) {
    stockfish.current.terminate();
  }

  const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
  stockfish.current = new Worker(stockfishPath, { type: "module" });

  stockfish.current.onmessage = (event) => {
    const message = event.data;
    console.log(message);

    if (message.includes("info depth") && message.includes("score cp")) {
      const parts = message.split(" ");
      const depthIndex = parts.indexOf("depth");
      let depth = null;
      if (depthIndex !== -1) {
        depth = parts[depthIndex + 1];
      }

      const cpIndex = parts.indexOf("cp");
      if (cpIndex !== -1) {
        let score = parseInt(parts[cpIndex + 1], 10) / 100;

        if (game.turn() === "b") {
          score *= -1;
        }

        // setEvaluation(`Score: ${score} (Depth: ${depth}) Turn: ${game.turn()}`);
        setEvaluation(score); // Store just the number instead of a string
      }

      const pvIndex = parts.indexOf("pv");
      if (pvIndex !== -1) {
        const pv = parts.slice(pvIndex + 1);
        setPvMoves(pv);
      }
    }
  };

  stockfish.current.postMessage("uci");
};

export const evaluatePositionSequentially = (fen) => {
  return new Promise((resolve) => {
    const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
    const stockfishWorker = new Worker(stockfishPath, { type: "module" });

    let resolved = false;
    let latestScore = null; // To store the latest score received
    let latestMate = null; // To store the latest mate evaluation

    const handleStockfishMessage = (event) => {
      const message = event.data;
      console.log("Stockfish message:", message);

      if (message.includes("info")) {
        const parts = message.split(" ");
        const cpIndex = parts.indexOf("cp");
        const mateIndex = parts.indexOf("mate");

        if (cpIndex !== -1) {
          latestScore = parseInt(parts[cpIndex + 1], 10) / 100;
        } else if (mateIndex !== -1) {
          latestMate = parseInt(parts[mateIndex + 1], 10);
        }
      }
    };

    stockfishWorker.addEventListener("message", handleStockfishMessage);

    stockfishWorker.postMessage("uci");
    stockfishWorker.postMessage(`position fen ${fen}`);
    stockfishWorker.postMessage("go movetime 2000"); // Set Stockfish to search for 2 seconds

    setTimeout(() => {
      if (!resolved) {
        resolved = true;

        // Choose the last evaluation available
        let finalScore = 0;
        if (latestMate !== null) {
          finalScore = latestMate > 0 ? 100 : -100; // Assign a high score for mate
        } else if (latestScore !== null) {
          finalScore = latestScore; // Use the most recent score
        }

        stockfishWorker.removeEventListener("message", handleStockfishMessage);
        stockfishWorker.terminate();
        resolve(finalScore);
      }
    }, 2500); // Ensure the timeout slightly exceeds the search time
  });
};
