// src/InfoModal.jsx
import React from "react";
import { Modal, Box, Typography, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const InfoModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="firechess-info-title"
      aria-describedby="firechess-info-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: { xs: "90%", sm: "80%", md: "60%" },
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: "8px",
          outline: "none",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {/* Modal Content */}
        <Typography
          id="firechess-info-title"
          variant="h5"
          gutterBottom
          align="center"
        >
          🔥 Welcome to FireChess 🔥
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Typography variant="h6" gutterBottom>
          What Makes FireChess So Lit?
        </Typography>

        {/* List of Features */}
        <Box sx={{ pl: 2, mb: 2 }}>
          <Typography variant="body1" gutterBottom>
            <strong>🔥 Fire-Themed Move Highlights</strong>
            <br />
            Celebrate your 🔥 moves! Our badge system lights up your best plays
            with vibrant fire icons, making it easy to spot and learn from your
            hottest strategies.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>🧠 Powered by Stockfish 16</strong>
            <br />
            Get sharp insights with the new Stockfish 16 engine. Receive
            accurate and in-depth evaluations for every position in your game.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>📈 Automatic PGN Playback</strong>
            <br />
            Let FireChess replay your games automatically. Just load your PGN
            files and watch your moves unfold step by step, enhanced by
            real-time analysis.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>📊 Progress Tracking</strong>
            <br />
            Keep tabs on your improvement. Track your move evaluations,
            centipawn losses, and overall game performance over time with
            detailed progress indicators.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>🔍 Detailed Move Insights</strong>
            <br />
            Dive deep into your strategies. Access comprehensive details for
            each move, including evaluation scores, potential alternatives, and
            strategic suggestions to refine your gameplay.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>🌐 Community Integration</strong>
            <br />
            Connect with fellow chess enthusiasts. Share your analyses, join
            challenges, and learn from others' strategies in our vibrant
            community.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>🚀 Fast and Responsive Performance</strong>
            <br />
            No lag, all action. Enjoy swift and reliable performance, ensuring
            your analysis sessions are smooth and efficient, no matter how long
            your games are.
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Let's Get Started!
        </Typography>

        <Typography variant="body1" gutterBottom>
          Light up your chess journey with FireChess and transform the way you
          analyze and improve your game.
        </Typography>

        <Typography variant="h6" gutterBottom align="center">
          🔥 FireChess – Ignite Your Chess 🔥
        </Typography>
      </Box>
    </Modal>
  );
};

export default InfoModal;
