// src/Components/FireStreak.jsx
import React from "react";
import { Box, Typography } from "@mui/material";

const FireStreak = ({ count }) => {
  if (count <= 0) return null;

  const maxIcons = 5;
  const displayCount = Math.min(count, maxIcons);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {Array.from({ length: displayCount }).map((_, idx) => (
        <span key={idx} role="img" aria-label="fire" style={{ marginRight: 2 }}>
          🔥
        </span>
      ))}
      {count > maxIcons && (
        <Typography
          variant="body2"
          color="secondary.main"
          sx={{
            marginLeft: 1,
            fontWeight: "bold", // Makes the multiplier text bold
          }}
        >
          x{count}
        </Typography>
      )}
    </Box>
  );
};

export default FireStreak;
