// src/ChessAnalyzer.jsx
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Chessboard } from "react-chessboard";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Button,
  MenuItem, // Ensure MenuItem is imported
} from "@mui/material";
import {
  PlayArrow,
  Pause,
  Info as InfoIcon,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import * as pgnParser from "pgn-parser";
import { Chess } from "chess.js";
import {
  initializeStockfish,
  evaluatePositionSequentially,
} from "./helpers/chessUtils";
import CustomSquareRenderer from "./CustomSquareRenderer";
import PlayerSymbol from "./Components/PlayerSymbol";
import FireStreak from "./Components/FireStreak";
import InfoModal from "./Components/InfoModal";

const ChessAnalyzer = () => {
  const [game, setGame] = useState(new Chess());
  const [evaluation, setEvaluation] = useState(0); // Initialized to 0
  const [moveDetails, setMoveDetails] = useState([]);
  const [pvMoves, setPvMoves] = useState([]);
  const [pgnInput, setPgnInput] = useState("");
  const [averageLoss, setAverageLoss] = useState({ white: 0, black: 0 });
  const [badges, setBadges] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [totalMoves, setTotalMoves] = useState(0);
  const [moves, setMoves] = useState([]);
  const [progress, setProgress] = useState(0);
  const [previousEval, setPreviousEval] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const stockfish = useRef(null);
  const moveDetailsRef = useRef(null);
  const evaluationsCache = useRef([]);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen((prev) => !prev);

  // Player Info
  const [playerInfo, setPlayerInfo] = useState({
    white: { name: "White", elo: null },
    black: { name: "Black", elo: null },
  });

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [fireStreaks, setFireStreaks] = useState({
    white: 0,
    black: 0,
  });

  // New State Variables for Report
  const [maxFireStreak, setMaxFireStreak] = useState({ white: 0, black: 0 });
  const [emojiCounts, setEmojiCounts] = useState({
    white: { excellent: 0, good: 0, inaccuracy: 0, mistake: 0, blunder: 0 },
    black: { excellent: 0, good: 0, inaccuracy: 0, mistake: 0, blunder: 0 },
  });
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportData, setReportData] = useState({
    white: {},
    black: {},
  });

  // State for Famous Games
  const [famousGames, setFamousGames] = useState([]);
  const [selectedFamousGame, setSelectedFamousGame] = useState("");
  const [currentGameTitle, setCurrentGameTitle] = useState("");

  // Fetch and parse famous_games.txt on mount
  useEffect(() => {
    const fetchFamousGames = async () => {
      try {
        const response = await fetch("/famous_games.txt");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const parsedGames = parseFamousGames(text);
        console.log(`Parsed ${parsedGames.length} famous games.`);
        setFamousGames(parsedGames);
      } catch (error) {
        console.error("Error fetching famous games:", error);
      }
    };

    fetchFamousGames();
  }, []);

  // Helper function to parse famous_games.txt
  const parseFamousGames = (text) => {
    const games = [];
    // Split the text using '///' as the separator, accounting for possible surrounding whitespace
    const rawGames = text.split(/\/\/\//g);

    rawGames.forEach((rawGame) => {
      // Trim to remove leading/trailing whitespace
      const trimmedGame = rawGame.trim();
      if (trimmedGame === "") return; // Skip empty segments

      // Split the game into lines
      const lines = trimmedGame.split(/\r?\n/);

      // The first non-empty line is the title
      let title = "";
      let pgnStartIndex = 0;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].trim() !== "") {
          title = lines[i].trim();
          pgnStartIndex = i + 1;
          break;
        }
      }

      // The rest of the lines constitute the PGN
      const pgnLines = lines.slice(pgnStartIndex).join("\n").trim();

      // Validate that PGN starts with headers
      if (title && pgnLines.startsWith("[")) {
        games.push({ title, pgn: pgnLines });
      }
    });

    return games;
  };

  useEffect(() => {
    initializeStockfish(stockfish, setEvaluation, setPvMoves, game);
    return () => {
      if (stockfish.current) {
        stockfish.current.terminate();
      }
    };
  }, [game]);

  useEffect(() => {
    console.log("Current Evaluation:", evaluation, typeof evaluation); // Debugging
    if (moveDetailsRef.current) {
      moveDetailsRef.current.scrollTop = moveDetailsRef.current.scrollHeight;
    }
  }, [moveDetails, evaluation]);

  const onDrop = (sourceSquare, targetSquare) => {
    let move;

    try {
      move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });
    } catch (error) {
      console.error(`Error making move: ${error}`);
      return false;
    }

    if (move === null) {
      console.warn(
        `Invalid move: {from: "${sourceSquare}", to: "${targetSquare}"}`
      );
      return false;
    }

    setGame(new Chess(game.fen()));
    evaluatePosition(game.fen());
    return true;
  };

  const evaluatePosition = (fen) => {
    initializeStockfish(stockfish, setEvaluation, setPvMoves, game);
    stockfish.current.postMessage(`position fen ${fen}`);
    stockfish.current.postMessage("go depth 18");
  };

  const calculateAverageLoss = () => {
    const whiteMoves = moveDetails.filter((detail) => detail.color === "White");
    const blackMoves = moveDetails.filter((detail) => detail.color === "Black");

    const whiteTotalLoss = whiteMoves.reduce(
      (acc, move) => acc + parseFloat(move.loss),
      0
    );
    const blackTotalLoss = blackMoves.reduce(
      (acc, move) => acc + parseFloat(move.loss),
      0
    );

    const whiteAverage =
      whiteMoves.length > 0
        ? (whiteTotalLoss / whiteMoves.length).toFixed(2)
        : "N/A";
    const blackAverage =
      blackMoves.length > 0
        ? (blackTotalLoss / blackMoves.length).toFixed(2)
        : "N/A";

    return { white: whiteAverage, black: blackAverage };
  };

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const startAnalysis = async () => {
    if (moves.length === 0) return;

    let whiteCpLoss = 0;
    let blackCpLoss = 0;
    let whiteMoveCount = 0;
    let blackMoveCount = 0;
    const badgeMapping = {
      excellent: "🔥",
      good: "✅",
      inaccuracy: "⚠️",
      mistake: "❓",
      blunder: "❌",
    };

    const analyzeMove = async (index) => {
      if (index >= moves.length || !isPlaying) return;

      const move = moves[index];
      const moveResult = game.move(move, { sloppy: true });
      if (!moveResult) {
        console.error(`Invalid move at index ${index}: ${move}`);
        return;
      }

      const lastMoveSquare = moveResult.to;
      const currentFen = game.fen();
      setGame(new Chess(currentFen));

      // Check if evaluation is cached
      let currentEval;
      if (evaluationsCache.current[index]) {
        currentEval = evaluationsCache.current[index].eval;
      } else {
        const rawEval = await evaluatePositionSequentially(currentFen);
        currentEval = game.turn() === "b" ? -rawEval : rawEval;
        evaluationsCache.current[index] = {
          moveNumber: index + 1,
          eval: currentEval,
        };
      }

      setEvaluation(Number(currentEval) || 0); // Ensure numeric

      // Introduce weighting based on the magnitude of the previous evaluation
      const weightFactor = 1 / (1 + Math.abs(previousEval));
      const weightedCpLoss =
        Math.abs(currentEval - previousEval) * weightFactor;

      // Optionally cap the weightedCpLoss to prevent extreme values
      let cappedCpLoss = Math.min(weightedCpLoss, 10); // Example cap at 10 centipawns

      setPreviousEval(currentEval);

      // Determine if the current evaluation indicates mate
      const isMate = Math.abs(currentEval) >= 100;
      cappedCpLoss = isMate ? 0 : cappedCpLoss;
      const effectiveCpLoss = isMate ? 0 : cappedCpLoss * 100;

      let badge = "";
      if (cappedCpLoss <= 0.25) badge = "excellent";
      else if (cappedCpLoss <= 0.5) badge = "good";
      else if (cappedCpLoss <= 0.75) badge = "inaccuracy";
      else if (cappedCpLoss <= 1.5) badge = "mistake";
      else badge = "blunder";

      const badgeSymbol = badgeMapping[badge];

      // Determine which player made the move
      const player = game.turn() === "w" ? "black" : "white";

      // **Update badges with timed removal**
      setBadges((prevBadges) => ({
        ...prevBadges,
        [lastMoveSquare]: {
          symbol: badgeSymbol,
          loss:
            badge === "inaccuracy" || badge === "mistake" || badge === "blunder"
              ? (cappedCpLoss * 100).toFixed(1)
              : null,
          streakLost: badge !== "excellent",
        },
      }));

      // Handle Fire Streaks and Max Fire Streaks
      setFireStreaks((prevStreaks) => {
        const newStreaks = { ...prevStreaks };
        if (badge === "excellent") {
          newStreaks[player] += 1;
          // Update Max Fire Streak
          setMaxFireStreak((prevMax) => ({
            ...prevMax,
            [player]: Math.max(prevMax[player], newStreaks[player]),
          }));
        } else {
          newStreaks[player] = 0;
        }
        return newStreaks;
      });

      // Update Emoji Counts
      setEmojiCounts((prevCounts) => ({
        ...prevCounts,
        [player]: {
          ...prevCounts[player],
          [badge]: prevCounts[player][badge] + 1,
        },
      }));

      // Remove badge after 2 seconds
      setTimeout(() => {
        setBadges((prevBadges) => {
          const updatedBadges = { ...prevBadges };
          delete updatedBadges[lastMoveSquare];
          return updatedBadges;
        });
      }, 2000); // 2000 milliseconds = 2 seconds

      if (player === "black") {
        blackCpLoss += effectiveCpLoss;
        blackMoveCount++;
        setMoveDetails((prev) => [
          ...prev,
          {
            moveNumber: index + 1,
            color: "Black",
            loss: effectiveCpLoss.toFixed(2),
          },
        ]);
      } else {
        whiteCpLoss += effectiveCpLoss;
        whiteMoveCount++;
        setMoveDetails((prev) => [
          ...prev,
          {
            moveNumber: index + 1,
            color: "White",
            loss: effectiveCpLoss.toFixed(2),
          },
        ]);
      }

      setProgress(((index + 1) / moves.length) * 100);

      // If last move, prepare the report
      if (index + 1 === moves.length) {
        // Calculate average centipawn loss
        const averages = calculateAverageLoss({
          whiteCpLoss,
          blackCpLoss,
          whiteMoveCount,
          blackMoveCount,
        });

        setAverageLoss(averages);

        // Determine slang line and title based on average loss and max fire streak
        const determinePerformance = (avgLoss, maxStreak) => {
          let slang = "Great job!";
          let title = "Beginner";

          if (avgLoss !== "N/A") {
            const numericAvg = parseFloat(avgLoss);
            if (numericAvg < 30 && maxStreak >= 5) {
              slang = "You played like fire!";
              title = "Super GM";
            } else if (numericAvg < 50 && maxStreak >= 3) {
              slang = "Impressive moves!";
              title = "Grandmaster";
            } else if (numericAvg < 100 && maxStreak >= 2) {
              slang = "Solid performance!";
              title = "Expert";
            } else {
              slang = "Keep practicing!";
              title = "Beginner";
            }
          }

          return { slang, title };
        };

        const whitePerformance = determinePerformance(
          averages.white,
          maxFireStreak.white
        );
        const blackPerformance = determinePerformance(
          averages.black,
          maxFireStreak.black
        );

        setReportData({
          white: {
            name: playerInfo.white.name,
            maxFireStreak: maxFireStreak.white,
            emojiCounts: emojiCounts.white,
            averageLoss: averages.white,
            slang: whitePerformance.slang,
            title: whitePerformance.title,
          },
          black: {
            name: playerInfo.black.name,
            maxFireStreak: maxFireStreak.black,
            emojiCounts: emojiCounts.black,
            averageLoss: averages.black,
            slang: blackPerformance.slang,
            title: blackPerformance.title,
          },
        });

        // Open the Report Modal
        setReportModalOpen(true);
      }

      // Wait before analyzing next move to allow animations to play
      setTimeout(() => {
        setCurrentMoveIndex((prev) => prev + 1);
      }, 1500);
    };

    analyzeMove(currentMoveIndex);
  };

  useEffect(() => {
    if (isPlaying && currentMoveIndex < moves.length) {
      startAnalysis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMoveIndex, isPlaying]);

  const parsePgn = () => {
    if (!pgnInput.trim()) return;

    try {
      const parsedPgn = pgnParser.parse(pgnInput);
      if (!parsedPgn.length || !parsedPgn[0].moves) {
        alert("Invalid PGN");
        return;
      }

      const headers = parsedPgn[0].headers;

      // Helper function to extract header values
      const getHeaderValue = (name) => {
        const header = headers.find((h) => h.name === name);
        return header ? header.value : null;
      };

      setPlayerInfo({
        white: {
          name: getHeaderValue("White") || "White",
          elo: getHeaderValue("WhiteElo") || null,
        },
        black: {
          name: getHeaderValue("Black") || "Black",
          elo: getHeaderValue("BlackElo") || null,
        },
      });

      const parsedMoves = parsedPgn[0].moves.map((move) => move.move);
      setMoves(parsedMoves);
      setTotalMoves(parsedMoves.length);
      setCurrentMoveIndex(0);
      setProgress(0);
      setMoveDetails([]);
      setBadges({});
      setPreviousEval(0);
      evaluationsCache.current = [];
      game.reset();

      // Reset Report Data
      setMaxFireStreak({ white: 0, black: 0 });
      setEmojiCounts({
        white: { excellent: 0, good: 0, inaccuracy: 0, mistake: 0, blunder: 0 },
        black: { excellent: 0, good: 0, inaccuracy: 0, mistake: 0, blunder: 0 },
      });
      setAverageLoss({ white: 0, black: 0 });
      setReportData({
        white: {},
        black: {},
      });

      // If the PGN was selected from famous games, set the title
      if (selectedFamousGame) {
        setCurrentGameTitle(selectedFamousGame);
      } else {
        setCurrentGameTitle("Custom PGN");
      }

      // Automatically start playing after loading PGN
      setIsPlaying(true);

      // Close the modal
      setModalOpen(false);
    } catch (error) {
      console.error("Error parsing PGN:", error);
      alert("An error occurred while parsing the PGN");
    }
  };

  const toggleAutoPlay = () => {
    setIsPlaying((prev) => !prev);
  };

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  // Handle Back Move
  const handleBack = () => {
    if (currentMoveIndex === 0) return;
    setIsPlaying(false); // Pause autoplay when manually navigating
    const newIndex = currentMoveIndex - 1;
    setCurrentMoveIndex(newIndex);
    const newGame = new Chess();
    for (let i = 0; i < newIndex; i++) {
      newGame.move(moves[i], { sloppy: true });
    }
    setGame(newGame);
    setEvaluation(
      evaluationsCache.current[newIndex - 1]
        ? evaluationsCache.current[newIndex - 1].eval.toFixed(2)
        : 0
    );
  };

  // Handle Forward Move
  const handleForward = () => {
    if (currentMoveIndex >= moves.length) return;
    setIsPlaying(false); // Pause autoplay when manually navigating
    const newIndex = currentMoveIndex + 1;
    setCurrentMoveIndex(newIndex);
    const newGame = new Chess();
    for (let i = 0; i < newIndex; i++) {
      newGame.move(moves[i], { sloppy: true });
    }
    setGame(newGame);
    setEvaluation(
      evaluationsCache.current[newIndex - 1]
        ? evaluationsCache.current[newIndex - 1].eval.toFixed(2)
        : 0
    );
  };

  const boardWidth = Math.min(window.innerWidth, window.innerHeight) * 0.6;

  // Memoize customSquare function
  const customSquare = useCallback(
    (props) => {
      const badgeInfo = badges[props.square];
      return (
        <CustomSquareRenderer
          {...props}
          badge={badgeInfo ? badgeInfo.symbol : null}
          loss={badgeInfo ? badgeInfo.loss : null}
          streakLost={badgeInfo ? badgeInfo.streakLost : false}
        />
      );
    },
    [badges]
  );

  // Function to close the report modal
  const closeReportModal = () => {
    setReportModalOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        color: "text.primary",
        minHeight: "100vh",
      }}
    >
      <InfoModal
        open={infoModalOpen}
        handleClose={() => setInfoModalOpen(false)}
      />

      {/* Report Modal */}
      <Modal open={reportModalOpen} onClose={closeReportModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "90%",
            maxWidth: "600px",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Analysis Report
          </Typography>
          <Grid container spacing={4}>
            {/* White Player Report */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {reportData.white.name}
              </Typography>
              <Typography variant="body1">
                <strong>Title:</strong> {reportData.white.title || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Slang:</strong> {reportData.white.slang || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Highest Fire Streak:</strong>{" "}
                {reportData.white.maxFireStreak}
              </Typography>
              <Typography variant="body1">
                <strong>Average Centipawn Loss:</strong>{" "}
                {reportData.white.averageLoss}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>Move Types:</strong>
              </Typography>
              <Grid container spacing={1} sx={{ mt: 0.5 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    🔥 Excellent: {reportData.white.emojiCounts?.excellent || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ✅ Good: {reportData.white.emojiCounts?.good || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ⚠️ Inaccuracy:{" "}
                    {reportData.white.emojiCounts?.inaccuracy || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ❓ Mistake: {reportData.white.emojiCounts?.mistake || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ❌ Blunder: {reportData.white.emojiCounts?.blunder || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Black Player Report */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {reportData.black.name}
              </Typography>
              <Typography variant="body1">
                <strong>Title:</strong> {reportData.black.title || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Slang:</strong> {reportData.black.slang || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Highest Fire Streak:</strong>{" "}
                {reportData.black.maxFireStreak}
              </Typography>
              <Typography variant="body1">
                <strong>Average Centipawn Loss:</strong>{" "}
                {reportData.black.averageLoss}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>Move Types:</strong>
              </Typography>
              <Grid container spacing={1} sx={{ mt: 0.5 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    🔥 Excellent: {reportData.black.emojiCounts?.excellent || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ✅ Good: {reportData.black.emojiCounts?.good || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ⚠️ Inaccuracy:{" "}
                    {reportData.black.emojiCounts?.inaccuracy || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ❓ Mistake: {reportData.black.emojiCounts?.mistake || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    ❌ Blunder: {reportData.black.emojiCounts?.blunder || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={closeReportModal}
            sx={{ mt: 4 }}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>

      <AppBar position="static" sx={{ backgroundColor: "primary.main" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            FireChess
          </Typography>
          <IconButton color="inherit" onClick={() => setInfoModalOpen(true)}>
            <InfoIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ paddingTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {/* Display Current Game Title */}
            <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              {currentGameTitle || "Chess Analyzer"}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 2,
              }}
            >
              {/* White Player Info Box */}
              <Box
                sx={{
                  padding: 2,
                  border: "1px solid",
                  borderColor: "grey.300",
                  borderRadius: "8px",
                  backgroundColor: "background.paper",
                  width: "45%",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* Header: Symbol and Name */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PlayerSymbol color="white" />
                  <Typography variant="body1">
                    {playerInfo.white.name}
                  </Typography>
                </Box>

                {/* Info Section: ELO and Fire Streak */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  {/* ELO */}
                  <Typography
                    fontSize={17}
                    variant="body2"
                    color="text.secondary"
                  >
                    ELO: {playerInfo.white.elo || "N/A"}
                  </Typography>

                  {/* Fire Streak (aligned to the right) */}
                  <FireStreak count={fireStreaks.white} />
                </Box>
              </Box>

              {/* Black Player Info Box */}
              <Box
                sx={{
                  padding: 2,
                  border: "1px solid",
                  borderColor: "grey.300",
                  borderRadius: "8px",
                  backgroundColor: "background.paper",
                  width: "45%",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  textAlign: "right",
                }}
              >
                {/* Header: Symbol and Name */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <PlayerSymbol color="black" />
                  <Typography variant="body1">
                    {playerInfo.black.name}
                  </Typography>
                </Box>

                {/* Info Section: Fire Streak and ELO */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  {/* Fire Streak (aligned to the left) */}
                  <FireStreak count={fireStreaks.black} />

                  {/* ELO */}
                  <Typography
                    fontSize={17}
                    variant="body2"
                    color="text.secondary"
                  >
                    ELO: {playerInfo.black.elo || "N/A"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  "& > div": {
                    width: "auto !important",
                  },
                }}
              >
                <Chessboard
                  position={game.fen()}
                  onPieceDrop={onDrop}
                  boardWidth={boardWidth}
                  customSquare={customSquare}
                  customBoardStyle={{
                    borderRadius: "4px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                  }}
                  customDarkSquareStyle={{
                    backgroundColor: "#DD8353FF",
                  }}
                  boardStyle={{
                    border: "2px solid",
                    borderColor: "primary.main",
                    borderRadius: "8px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: 20,
                  height: boardWidth,
                  marginLeft: 1,
                  position: "relative",
                  overflow: "hidden",
                  border: "1px solid",
                  borderColor: "grey.300",
                  background: `linear-gradient(to bottom, 
        black ${clamp(50 - evaluation * 5, 0, 100)}%, 
        white ${clamp(50 - evaluation * 5, 0, 100)}%
      )`,
                  transition: "background 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                  alignSelf: "flex-start",
                }}
              >
                {/* Fixed middle marker */}
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: "50%",
                    height: 2,
                    backgroundColor: "#ff9800", // Orange color
                    transform: "translateY(-50%)",
                    zIndex: 2,
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: `${clamp(50 - evaluation * 5, 0, 100)}%`,
                    transform: "translateY(-50%)",
                    fontSize: "0.75rem",
                    color: "text.secondary",
                    zIndex: 2,
                    transition: "top 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                  }}
                >
                  {Math.abs(evaluation).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            {/* Display Current Game Title */}
            <Typography variant="h5" align="center" sx={{ mt: 2, mb: 2 }}>
              {currentGameTitle || "Chess Analyzer"}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                gap: 2,
              }}
            >
              <IconButton
                color="primary"
                onClick={handleBack}
                disabled={currentMoveIndex === 0}
              >
                <ArrowBack />
              </IconButton>
              <Button
                variant="contained"
                color="secondary"
                onClick={toggleAutoPlay}
                startIcon={isPlaying ? <Pause /> : <PlayArrow />}
              >
                {isPlaying ? "Pause" : "Play"}
              </Button>
              <IconButton
                color="primary"
                onClick={handleForward}
                disabled={currentMoveIndex >= moves.length}
              >
                <ArrowForward />
              </IconButton>
              <Button
                variant="outlined"
                color="secondary"
                onClick={toggleModal}
              >
                Load PGN
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {evaluation !== null ? (
                <span>
                  Evaluation:
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ color: "secondary.main", ml: 1 }}
                  >
                    {evaluation}
                  </Typography>
                </span>
              ) : (
                <span>Evaluation: N/A</span>
              )}
            </Typography>

            {/* Load PGN Modal */}
            <Modal open={modalOpen} onClose={toggleModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  width: "400px",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Load PGN
                </Typography>

                {/* Dropdown for Famous Games */}
                <TextField
                  select
                  label="Select a Famous Game"
                  value={selectedFamousGame || ""}
                  onChange={(e) => {
                    const selectedTitle = e.target.value;
                    const selectedGame = famousGames.find(
                      (game) => game.title === selectedTitle
                    );
                    if (selectedGame) {
                      setPgnInput(selectedGame.pgn);
                      setSelectedFamousGame(selectedTitle);
                    } else {
                      setSelectedFamousGame("");
                      setPgnInput("");
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {famousGames.map((game, index) => (
                    <MenuItem key={index} value={game.title}>
                      {game.title}
                    </MenuItem>
                  ))}
                </TextField>

                <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                  OR
                </Typography>

                {/* TextField for Pasting PGN */}
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={pgnInput}
                  onChange={(e) => {
                    setPgnInput(e.target.value);
                    setSelectedFamousGame(""); // Clear selection if user types manually
                  }}
                  variant="outlined"
                  placeholder="Paste your PGN here..."
                  sx={{ mb: 2 }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={parsePgn}
                >
                  Load PGN
                </Button>
              </Box>
            </Modal>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Move: {currentMoveIndex} / {totalMoves}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 1, height: 10, borderRadius: 5 }}
              color="secondary"
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Move Details:
            </Typography>
            <Box
              ref={moveDetailsRef}
              sx={{
                height: "300px",
                overflowY: "auto",
                border: "1px solid",
                borderColor: "secondary.main",
                padding: "8px",
                backgroundColor: "background.paper",
                borderRadius: "4px",
                zIndex: 10,
                mt: 1,
              }}
            >
              <pre style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>
                {moveDetails.length > 0
                  ? moveDetails
                      .map(
                        (detail) =>
                          `Move ${detail.moveNumber}: ${detail.color} loses ${detail.loss} centipawns.`
                      )
                      .join("\n")
                  : "No moves analyzed yet."}
                {moveDetails.length > 0 && (
                  <>
                    {"\n\n"}
                    {(() => {
                      const averages = calculateAverageLoss();
                      return `Average Centipawn Loss - White: ${averages.white}, Black: ${averages.black}`;
                    })()}
                  </>
                )}
              </pre>
            </Box>

            {/* Add the "Open Report" Button Here */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReportModalOpen(true)}
              disabled={!reportData.white.title}
              sx={{ mt: 2 }}
              fullWidth
            >
              Open Report
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ChessAnalyzer;
