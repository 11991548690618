// src/CustomSquareRenderer.jsx
import React, { forwardRef, useEffect, useState, useRef } from "react";

const CustomSquareRenderer = forwardRef((props, ref) => {
  const { children, square, squareColor, style, badge, loss, streakLost } =
    props;
  const [showLoss, setShowLoss] = useState(false);
  const [showStreakLost, setShowStreakLost] = useState(false);
  const lossTimerRef = useRef(null);
  const streakLostTimerRef = useRef(null);

  useEffect(() => {
    // Handle loss popup
    if (loss !== null && loss !== undefined) {
      setShowLoss(true);

      // Clear any existing timer to avoid multiple timers
      if (lossTimerRef.current) {
        clearTimeout(lossTimerRef.current);
      }

      lossTimerRef.current = setTimeout(() => {
        setShowLoss(false);
        lossTimerRef.current = null;
      }, 2000); // Duration matches the animation duration
    }

    // Handle streak lost popup
    if (streakLost) {
      setShowStreakLost(true);

      // Clear any existing timer to avoid multiple timers
      if (streakLostTimerRef.current) {
        clearTimeout(streakLostTimerRef.current);
      }

      streakLostTimerRef.current = setTimeout(() => {
        setShowStreakLost(false);
        streakLostTimerRef.current = null;
      }, 2000); // Duration matches the animation duration
    }

    // Cleanup timers on unmount
    return () => {
      if (lossTimerRef.current) {
        clearTimeout(lossTimerRef.current);
      }
      if (streakLostTimerRef.current) {
        clearTimeout(streakLostTimerRef.current);
      }
    };
  }, [loss, streakLost]);

  return (
    <div
      ref={ref}
      style={{
        ...style,
        position: "relative",
        zIndex: 2,
      }}
    >
      {children}
      {badge && (
        <div
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            height: 20,
            width: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: squareColor === "black" ? "#ff4500" : "#ff9f00", // Fiery colors
            color: "#ffffff",
            fontSize: 14,
            fontWeight: "bold",
            zIndex: 99999,
            animation: "sparkle 1s infinite",
          }}
        >
          {badge}
          {/* Add sparkle animation using CSS */}
          <style>
            {`
              @keyframes sparkle {
                0% { transform: scale(1); opacity: 1; }
                50% { transform: scale(1.3); opacity: 0.8; }
                100% { transform: scale(1); opacity: 1; }
              }
            `}
          </style>
        </div>
      )}
      {showLoss && loss !== null && loss !== undefined && (
        <div
          style={{
            position: "absolute",
            bottom: "110%", // Position slightly above the badge
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0,0,0,0.7)",
            color: "#fff",
            padding: "2px 6px",
            borderRadius: "4px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            animation: "floatFade 2s forwards",
            pointerEvents: "none",
            zIndex: 100001,
          }}
        >
          {`-${loss}`}
          {/* Define the float and fade animation */}
          <style>
            {`
              @keyframes floatFade {
                0% {
                  transform: translate(-50%, 0) translateY(0);
                  opacity: 1;
                }
                100% {
                  transform: translate(-50%, 0) translateY(-20px);
                  opacity: 0;
                }
              }
            `}
          </style>
        </div>
      )}
      {showStreakLost && (
        <div
          style={{
            position: "absolute",
            bottom: "150%", // Position further above to avoid collision
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(255,0,0,0.8)", // Red background to indicate loss
            color: "#fff",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            animation: "fadeOut 2s forwards",
            pointerEvents: "none",
            zIndex: 100002, // Higher z-index to appear above loss popup
            marginBottom: 4, // Small margin to separate from the badge
          }}
        >
          Streak Lost!
          {/* Define the fade-out animation */}
          <style>
            {`
              @keyframes fadeOut {
                0% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                }
              }
            `}
          </style>
        </div>
      )}
    </div>
  );
});

export default React.memo(CustomSquareRenderer);
