// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark", // Ensures dark background by default
    primary: {
      main: "#ff4500", // Orange-Red
    },
    secondary: {
      main: "#ff9f00", // Bright Orange
    },
    background: {
      default: "#1e1e1e", // Dark background
      paper: "#333333", // Slightly lighter for paper elements
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#ff9f00", // Orange text for accents
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export default theme;
