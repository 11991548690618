// src/App.js
import React from "react";
import ChessAnalyzer from "./ChessAnalyzer";
import ParticleBackground from "./ParticleBackground";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="App"
        style={{
          position: "relative",
          minHeight: "100vh", // Ensure the parent has a height
        }}
      >
        <ParticleBackground />
        <ChessAnalyzer />
      </div>
    </ThemeProvider>
  );
}

export default App;
