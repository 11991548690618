// src/ParticleBackground.jsx
import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ParticleBackground = () => {
  const particlesInit = async (engine) => {
    await loadFull(engine);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        fullScreen: { enable: false },
        background: { color: { value: "transparent" } },
        fpsLimit: 60,
        particles: {
          number: {
            value: 0,
            limit: 50, // Added this line to set the maximum number of particles
          },
          color: { value: ["#ff9f00", "#ff4500", "#ff0000"] },
          shape: { type: "circle" },
          opacity: {
            value: 0.9,
            animation: {
              enable: true,
              speed: 1,
              minimumValue: 0.5,
              sync: false,
            },
          },
          size: { value: { min: 1, max: 3 } },
          move: {
            enable: true,
            speed: { min: 2, max: 5 },
            direction: "top",
            outModes: { default: "out" },
          },
        },
        emitters: {
          direction: "top",
          rate: {
            delay: 4, // Increased delay to emit particles less frequently
            quantity: 2, // Reduced quantity to emit fewer particles each time
          },
          size: {
            width: 100, // Width of the emitter area
            height: 0, // Height of the emitter area (0 for a line)
          },
          position: {
            x: 50, // Emitter centered horizontally
            y: 100, // Emitter at the bottom of the canvas
          },
        },
        detectRetina: true,
      }}
      style={{
        position: "absolute", // Positioning relative to parent
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
        pointerEvents: "none",
      }}
    />
  );
};

export default ParticleBackground;
